<template>
    <div>
        <div class="text-xs-right">
            <v-btn class="blue" small round color="primary" :to="{name: 'njangi_events.new_event'}" >
                <v-icon left class="fas fa-plus " > </v-icon>
                <span> Create New Event</span>
            </v-btn>
        </div>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus aspernatur nesciunt corporis vitae quia accusantium, architecto dicta consectetur, distinctio porro sequi. Quo numquam est impedit ex delectus quibusdam recusandae eveniet?</p>
        <v-layout row wrap class="">
            <v-flex xs12 sm6 md4 lg3 v-for="item in events" :key="item.id" class="pa-1">
                <v-hover>
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" :to="item.link" style="cursor: pointer; ">
                        <v-img
                                src=""
                                aspect-ratio="2.75"
                                height="150px"
                                :class="item.color"
                        > </v-img>

                        <v-card-title primary-title >
                            <div>
                                <h3 class="heading mb-0" >{{item.title}}</h3>
                                <div>
                                    {{item.description}}
                                </div>
                            </div>
                        </v-card-title>

                    </v-card>
                </v-hover>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                events: [
                    {
                        id: '1',
                        title: 'Visiting of Yaounde Saint Orphanage',
                        description: '',
                        color: 'orange',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '2',
                        title: 'Providing for the homeless in Yde',
                        description: '',
                        color: 'teal',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '3',
                        title: 'Internally deplaced People in Bamenda',
                        description: '',
                        color: 'blue',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '4',
                        title: 'Support for students in secondary schools',
                        description: '',
                        color: 'purple',
                        link: '',
                        add_link: false
                    },
                    {
                        id: '5',
                        title: 'Sensitisations for youths on argriculture and business',
                        description: '',
                        color: 'green',
                        link: '',
                        add_link: false
                    }
                ]
            }
        }

    }
</script>
